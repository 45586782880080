import Button from "@material-ui/core/Button/Button";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";

interface Props {
  onResend: () => Promise<void>;
}

export default function ConfirmEmailWarning({ onResend }: Props) {
  return (
    <Alert
      variant="outlined"
      severity="warning"
      action={
        <Button color="inherit" size="large" onClick={onResend}>
          Resend
        </Button>
      }
    >
      <AlertTitle>Email not confirmed</AlertTitle>
      Please confirm your email address by clicking on the confirmation link.
    </Alert>
  );
}
