import React from "react";
import { FormHelperText } from "@material-ui/core";
import { FormikInstance } from "utils/formikUtils";
import { GLOBAL_ERROR_KEY } from "projectConstants";

interface GlobalErrorFieldProps {
  formik: FormikInstance;
  Parent?: React.ComponentType;
  [key: string]: any;
}

export default function GlobalErrorField({
  formik,
  Parent,
  ...parentProps
}: GlobalErrorFieldProps) {
  const status = formik.status ? formik.status[GLOBAL_ERROR_KEY] : undefined;
  if (status === undefined) return null;
  const globalErrorField = (
    <FormHelperText id="component-helper-text" error={true}>
      {status}
    </FormHelperText>
  );
  if (Parent === undefined) return globalErrorField;
  else return <Parent {...parentProps}>{globalErrorField}</Parent>;
}
