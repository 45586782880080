import React, { useState } from "react";
import { FormikInstance } from "utils/formikUtils";
import FormikInputField from "./FormikInputField";
import TextField from "@material-ui/core/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface FormikPasswordFieldProps {
  formik: FormikInstance;
  fieldName: string;
  [key: string]: any;
}

export default function FormikPasswordField({
  formik,
  fieldName,
  ...props
}: FormikPasswordFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormikInputField
      Component={TextField}
      formik={formik}
      fieldName={fieldName}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(e: React.MouseEvent) => e.preventDefault()}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
