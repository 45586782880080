import React from "react";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

interface ProfileMenuProps {
  anchorElement: Element | null;
  open: boolean;
  setOpen: (arg0: boolean) => void;
  logout: (event: React.MouseEvent) => void;
}

export default function ProfileMenu({
  anchorElement,
  open,
  setOpen,
  logout,
}: ProfileMenuProps) {
  return (
    <Menu
      anchorEl={anchorElement}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={() => setOpen(false)}
      keepMounted
    >
      <MenuItem
        onClick={async (event: React.MouseEvent) => {
          setOpen(false);
          logout(event);
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
}
