import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    from,
  } from "@apollo/client";
  import { setContext } from "@apollo/client/link/context";
  import { getRefreshTokenLink } from "./refreshTokenLink";

  const API_ENDPOINT = '/graphql';
  
  const AUTH_ERROR_MSG = "You do not have permission to perform this action";
  
  const headersLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        "X-CSRFToken": (window as any).csrftoken,
      },
    };
  });
  
  const refreshLink = getRefreshTokenLink({
    isUnauthenticatedError: (graphQLError: {[key: string]: any}) => {
      if (graphQLError.message === AUTH_ERROR_MSG) {
        return true;
      }
      return false;
    },
    isAccessTokenValid: () => false,
    fetchNewAccessToken: () =>
      fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
          mutation refreshToken($input: RefreshInput!) {
            refreshToken(input: $input) {
              refreshExpiresIn
            }
          }
        `,
          variables: {
            input: {},
          },
        }),
      }),
  });
  
  const httpLink = createHttpLink({
    uri: API_ENDPOINT,
    credentials: "include",
  });
  
  const client = new ApolloClient({
    link: from([headersLink, refreshLink, httpLink]),
    cache: new InMemoryCache(),
  });
  
  export { client };
  