import { gql } from "@apollo/client";

export const deleteTokenCookieMutation = gql`
  mutation deleteTokenCookie($input: DeleteJSONWebTokenCookieInput!) {
    deleteTokenCookie(input: $input) {
      deleted
    }
  }
`;

export const revokeTokenMutation = gql`
  mutation revokeToken($input: RevokeInput!) {
    revokeToken(input: $input) {
      revoked
    }
  }
`;

export const deleteRefreshTokenCookieMutation = gql`
  mutation deleteTokenCookie($input: DeleteRefreshTokenCookieInput!) {
    deleteRefreshTokenCookie(input: $input) {
      deleted
    }
  }
`;
