import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";

function Spinner() {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const useStyles = makeStyles((theme: Theme) => ({
    spinnerContainer: {
      height,
    },
  }));
  const classes = useStyles();

  return (
    <Box
      className={classes.spinnerContainer}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={80} color="primary" />
    </Box>
  );
}

export default Spinner;
