import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Copyright from "components/common/Copyright";
import ProfileMenu from "components/common/ProfileMenu";
import IconButton from "@material-ui/core/IconButton";
import { AccountCircle } from "@material-ui/icons";
import { useMutation, useQuery } from "@apollo/client";
import { profileQuery } from "queries/profileQuery";
import Spinner from "components/common/Spinner";
import { useFormik } from "formik";
import { transformToFormikErrors } from "utils/formikUtils";
import { URL_NAMES, YUP_MESSAGES } from "projectConstants";
import { useSnackbar } from "notistack";
import FormikInputField from "components/fields/FormikInputField";
import { updateUserMutation } from "mutations/updateUser";
import SubmitButton from "components/fields/SubmitButton";
import ConfirmEmailWarning from "./ConfirmEmailWarning";
import GlobalErrorField from "components/fields/GlobalErrorField";
import FormikPasswordField from "components/fields/FormikPasswordField";
import { changePasswordMutation } from "mutations/changePassword";
import { resendActivationMailMutation } from "mutations/resendActivationMail";
import { manageStripeProfileMutation } from "mutations/manageStripeProfile";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import {
  deleteRefreshTokenCookieMutation,
  deleteTokenCookieMutation,
  revokeTokenMutation,
} from "mutations/logout";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  toolbar: {
    marginLeft: "auto",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  container: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Checkout() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const accountButtonRef = useRef(null);
  const profile = useQuery(profileQuery, { variables: {} });
  const [updateUser] = useMutation(updateUserMutation);
  const [changePassword] = useMutation(changePasswordMutation);
  const [resendActivationMail] = useMutation(resendActivationMailMutation);
  const [manageStripeProfile] = useMutation(manageStripeProfileMutation);
  // logout mutations
  const [deleteTokenCookie] = useMutation(deleteTokenCookieMutation);
  const [revokeToken] = useMutation(revokeTokenMutation);
  const [deleteRefreshTokenCookie] = useMutation(
    deleteRefreshTokenCookieMutation
  );

  useEffect(() => {
    if (!profile.loading && profile.error) history.push(URL_NAMES.LOGIN);
  }, [profile, history]);

  const handleManageSubscriptions = async () => {
    const { data } = await manageStripeProfile({
      variables: { input: { returnUrl: window.location.href } },
    });
    if (data.stripeCustomerPortal.redirectUrl) {
      window.location.replace(data.stripeCustomerPortal.redirectUrl);
    } else {
      const errorMessages = data.stripeCustomerPortal.errors;
      errorMessages.forEach(
        (errorObject: { field: string; messages: string[] }) => {
          if (errorObject)
            enqueueSnackbar(errorObject.messages[0], { variant: "error" });
        }
      );
    }
  };

  const resendConfirmation = async () => {
    resendActivationMail({
      variables: { input: { email: profile.data?.me.email } },
    }).then((response) => {
      if (response.data?.sendActivationMail?.errors) {
        const errorMessages = response.data.sendActivationMail.errors;
        errorMessages.forEach(
          (errorObject: { field: string; messages: string[] }) => {
            if (errorObject)
              enqueueSnackbar(errorObject.messages[0], { variant: "error" });
          }
        );
      } else {
        enqueueSnackbar("Activation mail sent successfully", {
          variant: "success",
        });
      }
    });
  };

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    deleteTokenCookie({ variables: { input: {} } });
    revokeToken({ variables: { input: {} } });
    deleteRefreshTokenCookie({ variables: { input: {} } });
    history.go(0);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: profile.data?.me.email,
      firstName: profile.data?.me.firstName,
      lastName: profile.data?.me.lastName,
    },
    onSubmit: async (values, { setStatus }) => {
      const response = await updateUser({
        variables: {
          input: {
            id: profile.data?.me.id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: "doesntMatterCantBeChanged",
          },
        },
      });
      if (response.data?.createUpdateUser?.errors) {
        setStatus(transformToFormikErrors(response.data.createUpdateUser));
      } else {
        setStatus({});
        enqueueSnackbar("Successfully updated", { variant: "success" });
        profile.refetch();
      }
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(YUP_MESSAGES.REQUIRED),
      lastName: Yup.string().required(YUP_MESSAGES.REQUIRED),
      email: Yup.string()
        .required(YUP_MESSAGES.REQUIRED)
        .email(YUP_MESSAGES.EMAIL),
    }),
  });

  const formikChangePassword = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
    },
    onSubmit: async (values, { setStatus }) => {
      const response = await changePassword({
        variables: {
          input: {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
          },
        },
      });
      if (response.data?.changePassword?.errors) {
        setStatus(transformToFormikErrors(response.data.changePassword));
      } else {
        setStatus({});
        enqueueSnackbar("Successfully changed password", {
          variant: "success",
        });
      }
    },
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required(YUP_MESSAGES.REQUIRED),
      newPassword: Yup.string().required(YUP_MESSAGES.REQUIRED),
    }),
  });

  if (profile.loading) return <Spinner />;
  if (profile.error) return <>`Error! ${profile.error?.message}`</>;

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="primary" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setProfileMenuOpen(true)}
            ref={accountButtonRef}
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
        <ProfileMenu
          anchorElement={accountButtonRef.current}
          open={profileMenuOpen}
          setOpen={setProfileMenuOpen}
          logout={handleLogout}
        />
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Manage Profile
          </Typography>
          <GlobalErrorField formik={formik} Parent={Grid} item />
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={3} className={classes.container}>
              {profile.data.me.status?.verified ? null : (
                <Grid item xl={12}>
                  <ConfirmEmailWarning onResend={resendConfirmation} />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <FormikInputField
                  Component={TextField}
                  formik={formik}
                  fieldName="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikInputField
                  Component={TextField}
                  formik={formik}
                  fieldName="lastName"
                  label="Last Name"
                />
              </Grid>
              <Grid item xs={12}>
                <FormikInputField
                  Component={TextField}
                  formik={formik}
                  fieldName="email"
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                direction="row"
                justifyContent="space-between"
              >
                <SubmitButton label="Update" />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleManageSubscriptions}
                >
                  Manage Subscriptions
                </Button>
              </Grid>
            </Grid>
          </form>
          <br />
          <Typography component="h1" variant="h4" align="center">
            Change Password
          </Typography>
          <GlobalErrorField formik={formikChangePassword} Parent={Grid} item />
          <form onSubmit={formikChangePassword.handleSubmit} noValidate>
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={12} sm={6}>
                <FormikPasswordField
                  formik={formikChangePassword}
                  fieldName="currentPassword"
                  label="Current Password"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikPasswordField
                  formik={formikChangePassword}
                  fieldName="newPassword"
                  label="New Password"
                />
              </Grid>
              <Grid item xs={12}>
                <SubmitButton label="Change" />
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}
