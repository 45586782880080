export const GLOBAL_ERROR_KEY = 'nonFieldErrors';

export const YUP_MESSAGES = {
    REQUIRED: 'Required',
    EMAIL: 'Please enter a valid email',
}

export const URL_NAMES = {
    LOGIN: '/profile/login',
    PROFILE: '/profile',
};