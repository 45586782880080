import Observable from "zen-observable";
import { onError } from "@apollo/client/link/error";

interface RefreshTokenLinkParams {
    isUnauthenticatedError: (graphQLErrors: {[key: string]: any}) => boolean;
    isAccessTokenValid: () => boolean;
    fetchNewAccessToken: () => Promise<Response>;
    onSuccessfulRefresh?: () => void;
    onFailedRefresh?: (error: string) => void;
}

export const getRefreshTokenLink = ({
  isUnauthenticatedError,
  isAccessTokenValid,
  fetchNewAccessToken,
  onSuccessfulRefresh,
  onFailedRefresh,
}: RefreshTokenLinkParams) =>
  onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (let i = 0; i < graphQLErrors.length; i += 1) {
        const graphQLError = graphQLErrors[i];

        if (isUnauthenticatedError(graphQLError)) {
          if (isAccessTokenValid()) {
            return forward(operation);
          }

          return new Observable((observer) => {
            fetchNewAccessToken()
              .then((newToken) => {
                if (!newToken) {
                  throw new Error("Unable to fetch new access token");
                }

                onSuccessfulRefresh && onSuccessfulRefresh();
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                onFailedRefresh && onFailedRefresh(error);
                observer.error(error);
              });
          });
        }
      }
    }

    return forward(operation);
  });
