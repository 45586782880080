import { gql } from "@apollo/client";

export const changePasswordMutation = gql`
  mutation changePassword($input: PasswordChangeInput!) {
    changePassword(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
