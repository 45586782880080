import Button from "@material-ui/core/Button/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

interface SubmitButtonProps {
  label: string;
  isSubmitting?: boolean;
  [key: string]: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    width: 85,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonProgress: {
    color: "green",
    position: "absolute",
  },
}));

export default function SubmitButton({
  label,
  isSubmitting = false,
  ...props
}: SubmitButtonProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        {...props}
      >
        {label}
      </Button>
      {isSubmitting && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}
