import { gql } from "@apollo/client";

export const manageStripeProfileMutation = gql`
  mutation manageStripeProfile($input: StripeCustomerPortalInput!) {
    stripeCustomerPortal(input: $input) {
      errors {
        field
        messages
      }
      redirectUrl
    }
  }
`;
