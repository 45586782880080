import { gql } from "@apollo/client";

export const resendActivationMailMutation = gql`
  mutation resendActivationMail($input: SendActivationMailInput!) {
    sendActivationMail(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
