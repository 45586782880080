import { gql } from "@apollo/client";

export const profileQuery = gql`
  query me {
    me {
      id
      firstName
      lastName
      email
      status {
        verified
      }
      subscribedCreators {
        edges {
          node {
            username
          }
        }
      }
    }
  }
`;