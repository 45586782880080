import { gql } from "@apollo/client";

export const updateUserMutation = gql`
    mutation updateUser($input: UserCreateUpdateInput!) {
        createUpdateUser(input: $input) {
            id,
            errors {
                field,
                messages
            }
        }
    }
`;