import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import SignIn from "components/views/signin";
import Profile from "components/views/profile";
import { URL_NAMES } from "projectConstants";
import theme from "utils/theme";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Router>
          <Switch>
            <Route path={URL_NAMES.LOGIN}>
              <SignIn />
            </Route>
            <Route path={URL_NAMES.PROFILE}>
              <Profile />
            </Route>
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
