import { gql } from "@apollo/client";

export const loginMutation = gql`
  mutation login($input: ObtainJSONWebTokenInput!) {
    tokenAuth(input: $input) {
      refreshToken
      errors {
        field
        messages
      }
    }
  }
`;